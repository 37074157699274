const websiteUrlMap = {
  map: 'https://map-sharp.meshstream.io/',
  'p2p-meshstream': 'https://streaming-sharp.meshstream.io/',
  'conference-random': [
    { name: 'Japan-Sharp', url: 'https://conference-sharp.meshstream.io/' },
    { name: 'office', url: 'https://conference-office.meshstream.io/' }
  ],
  'conference-cloud': 'https://conference.meshstream.io/',
  'conference-sharp': 'https://conference-sharp.meshstream.io/',
  'conference-office': 'https://conference-office.meshstream.io/',
  'conference-closest': 'https://conference-sharp.meshstream.io/',
  transcoder: 'https://transcoder.meshstream.io/',
  ipfs: 'https://ipfs-webui-sharp.meshstream.io'
};

export default websiteUrlMap;
