import { Navigate, useRoutes } from 'react-router-dom';
import { V2Home } from '@/modules/home/views';

// V1 為舊版的頁面，V2 為新版頁面
export default function Routes() {
  const routes = [
    { path: '/', element: <V2Home /> },
    { path: '*', element: <Navigate to="/" /> }
  ];
  return useRoutes(routes);
}
