import { useState } from 'react';
import { Layout, message } from 'antd';
import websiteUrlMap from './websiteUrlMap';
import { Iframe } from '@/modules/home/components';
import { Sidebar } from '@/shared/components';
import './V2Home.scss';

const { Sider } = Layout;

function getRandom(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function V2Home() {
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const [iframeUrl, setIframeUrl] = useState(websiteUrlMap['map']);

  function onSelectUrl({ key }) {
    if (key === 'conference-random') {
      // 目前隨機 2 選 1
      const index = getRandom(0, 1);
      setIframeUrl(websiteUrlMap[key][index].url);
      // 2 秒後通知，demo 比較真(模仿選 server 要一些時間)
      setTimeout(() => {
        message.info(`目前選中的機器為 ${websiteUrlMap[key][index].name}`, 5);
      }, 2000);
      return;
    }

    setIframeUrl(websiteUrlMap[key]);

    if (key === 'conference-closest') {
      // demo 用，寫死
      setTimeout(() => {
        message.info(`目前距離最近的機器為 Japan-Sharp`, 5);
      }, 2000);
    }
  }

  return (
    <div className="page">
      <Layout>
        <Sider
          breakpoint="lg"
          collapsible
          collapsed={siderCollapsed}
          collapsedWidth={45}
          onCollapse={value => setSiderCollapsed(value)}
        >
          <Sidebar onSelect={onSelectUrl} />
        </Sider>
        <Layout>
          <Iframe url={iframeUrl} />
        </Layout>
      </Layout>
    </div>
  );
}

export default V2Home;
