import { Menu } from 'antd';
import { Icon } from '@/shared/components';
import './Sidebar.scss';

function Sidebar({ onSelect }) {
  const items = [
    {
      key: 'map',
      label: 'Map',
      icon: <Icon icon="ic:outline-public" />
    },
    {
      key: 'p2p-meshstream',
      label: 'P2P Meshstream',
      icon: <Icon icon="ic:baseline-social-distance" />
    },
    {
      key: '4',
      label: 'Conference',
      icon: <Icon icon="ic:round-groups" />,
      children: [
        {
          key: 'conference-random',
          label: 'Random'
        },
        {
          key: '5',
          label: 'Selective',
          children: [
            {
              key: 'conference-cloud',
              label: 'Cloud',
              icon: <Icon icon="ic:outline-cloud" />
            },
            {
              key: 'conference-sharp',
              label: 'Japan-Sharp',
              icon: <Icon icon="ic:outline-location-on" />
            },
            {
              key: 'conference-office',
              label: 'Office(HK)',
              icon: <Icon icon="ic:outline-location-on" />
            }
          ]
        },
        {
          key: 'conference-closest',
          label: 'Closest'
        }
      ]
    },
    {
      key: 'transcoder',
      label: 'Transcoder',
      icon: <Icon icon="ic:baseline-video-settings" />
    },
    {
      key: 'ipfs',
      label: 'IPFS',
      icon: <Icon icon="ic:outline-hub" />
    }
  ];

  return (
    <Menu
      theme="dark"
      defaultSelectedKeys={['map']}
      mode="inline"
      items={items}
      onSelect={onSelect}
    />
  );
}

export default Sidebar;
